import React from "react";
import Box from "../components/box";
import Card from "../components/card";
import CardGroup from "../components/cardGroup";
import CardImage from "../components/cardImage";
import CardActionArea from "../components/cardActionArea";
import CoverImage from "../components/coverImage";
import ParallaxImage from "../components/parallaxImage";
import Layout from "../components/layout";
import TwoColumn from "../components/twoColumn";
import SafeZone from "../components/safeZone";
import SEO from "../components/seo";
import WhoImage from "../images/IMG_5740.jpg";

const Investors = () => (
  <Layout>
    <SEO title="Investors" />
    <TwoColumn>
      <SafeZone>
        <h2>Why?</h2>
        <p>We want to make a world where there is more great software.</p>
        <p>
          We unlock your ability to deliver to customers faster and cheaper; by
          automating your work-flow and creating happy teams.
        </p>
      </SafeZone>
      <CoverImage url="https://images.unsplash.com/photo-1472898965229-f9b06b9c9bbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
    </TwoColumn>
    <ParallaxImage
      height="halfScreen"
      url="https://images.unsplash.com/photo-1504192010706-dd7f569ee2be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80"
    >
      <CardGroup maxColumns={2}>
        <Card>
          <CardImage url="https://images.unsplash.com/photo-1517816428104-797678c7cf0c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" />
          <CardActionArea>
            <h3>Our values</h3>
            <p>
              Challenge assumptions, ask questions, don’t accept the status quo.
            </p>
            <p>
              Take ownership; all our partners and employees are expected to own
              everything they do.
            </p>
            <p>
              Make it worth it; we want to make a world where every engineer
              loves their job and the product they work on.
            </p>
            <p>
              Treat people like people; no arbitrary management, everyone is
              trusted to do their best work.
            </p>
          </CardActionArea>
        </Card>
        <Card>
          <CardImage url="https://images.unsplash.com/photo-1497935586351-b67a49e012bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80" />
          <CardActionArea>
            <h3>Our Speciality</h3>
            <p>
              Let our engineers do the:
              <Box>
                <span>Technology</span>
                <span>Engineering</span>
                <span>DevOps</span>
                <span>Infrastructure</span>
              </Box>
            </p>
            <p>
              So you can spend more time thinking about:
              <Box delay={1}>
                <span>Customers</span>
                <span>Innovation</span>
                <span>Growth</span>
                <span>Strategy</span>
              </Box>
            </p>
            <p>Improve communication &amp; engineering discipline.</p>
          </CardActionArea>
        </Card>
        <Card>
          <CardImage url="https://images.unsplash.com/photo-1482331293298-bf28cb04e2a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80" />
          <CardActionArea>
            <h3>The Team</h3>
            <p>
              Our experts have the talent your team needs to grow and are used
              to working in highly regulated, enterprise environments.
            </p>
            <p>
              We provide the changes and culture that allow our specialists to
              bring out the best in our clients teams.
            </p>
            <p>
              Our bespoke teams are more than just high performing engineers, we
              help organisations to transform by providing the training and new
              skills our clients need.
            </p>
          </CardActionArea>
        </Card>
      </CardGroup>
    </ParallaxImage>
    <ParallaxImage
      height="halfScreen"
      url="https://images.unsplash.com/photo-1504400739660-22ebeb14f00a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
    >
      <CardGroup maxColumns={2}>
        <Card>
          <CardImage url={WhoImage} />
          <CardActionArea>
            <h3>Who we are</h3>
            <p>
              We love technology; we are passionate about what we do and we’re
              really good at it.
            </p>
            <p>
              We challenge assumptions and address things that aren’t right.
            </p>
            <p>
              We put the team ahead of our egos; we hold our hands up when we
              are wrong and learn from our mistakes.
            </p>
            <p>
              We bring up others; we only hire people who mentor, lead, and
              coach. We don’t see problems, only challenges. We don’t give up
              when things go wrong and we push through.
            </p>
          </CardActionArea>
        </Card>
        <Card>
          <CardImage url="https://images.unsplash.com/photo-1569956726918-b36bd5e659b2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80" />
          <CardActionArea>
            <h3>Our Goals</h3>
            <p>
              The outsourcing/consulting market is broken: we are disrupting it.
            </p>
            <p>
              We have found a better outsourcing model that creates happier
              teams and better software.
            </p>
            <p>
              More than just numbers; our teams bring quality, teaching, and
              upskilling.
            </p>
            <p>
              We prioritise growing your permanent capability, over endless
              projects.
            </p>
            <p>We want to be the world’s SRE team.</p>
          </CardActionArea>
        </Card>
      </CardGroup>
    </ParallaxImage>
  </Layout>
);
export default Investors;
