import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Box = ({ children: [one, two, three, four], delay }) => (
  <span className="fpl__box" style={{ animationDelay: `${delay}s` }}>
    <span className="fpl__box--face-one">{one}</span>
    <span className="fpl__box--face-two">{two}</span>
    <span className="fpl__box--face-three">{three}</span>
    <span className="fpl__box--face-four">{four}</span>
  </span>
);

Box.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  delay: PropTypes.number,
};

Box.defaultProps = {
  delay: 0,
};

export default Box;
